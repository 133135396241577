<template>
    <div id="download">
        <div class="banner">
            <img src="../assets/images/old/banner3.png" />
            <div class="text-block">
                <h1>智能训练系统</h1>
                <h2>课程查看/案例分析/行情回放/交易训练</h2>
                <div class="red-btn" @click="download">
                    <img
                        class="download-icon"
                        src="../assets/images/download.png"
                    /><span>下载体验</span>
                </div>
            </div>
        </div>
        <div class="content">
            <h1>PC端软件下载</h1>
            <div class="red-line"></div>
            <ul>
                <li v-for="item in versionInfoList">
                    <div class="hd">
                        <div class="logo">
                            <img src="../assets/images/app-logo.png" />
                        </div>
                        <div class="version">
                            <h2>智能训练系统V{{ item.ver }}</h2>
                            <h3>更新日期：{{ item.dverdate }}</h3>
                        </div>
                        <div class="btn" @click="downloadHistoryVer(item.ver)">
                            下载
                        </div>
                    </div>
                    <div class="bd">
                        <p v-for="text in item.content">{{ text }}</p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                latestVersion: '',
                versionInfoList: []
            }
        },
        created() {
            this.getLatestVersion();
        },
        mounted() {
        },
        methods: {
            getLatestVersion() {
                let that = this;
                that.$comjs.post('/main/getSysVersionInfo', {}, function (im) {
                    console.log(im)
                    if (im.success) {
                        if (im.data.length > 0) {
                            that.versionInfoList = [im.data[0]];
                            that.latestVersion = im.data[0].ver;
                        }
                    }
                })
            },
            download() {
                window.open(`http://edu.18518.com:80/download/智能训练系统 Setup ${this.latestVersion}.exe`);
            },
            downloadHistoryVer(ver) {
                window.open(`http://edu.18518.com:80/download/智能训练系统 Setup ${ver}.exe`);
            }
        },
    }
</script>

<style lang="scss">
    #download {
        .banner {
            img {
                width: 100%;
                display: block;
            }
            .text-block {
                position: absolute;
                top: 24%;
                left: 52%;
                h1 {
                    font-size: 68px;
                    color: #ffffff;
                    letter-spacing: 6px;
                    font-weight: normal;
                    font-family: "custom-font";
                }
                h2 {
                    font-size: 36px;
                    font-weight: normal;
                    color: #d2d2d2;
                    margin-top: 30px;
                }
                .red-btn {
                    margin-top: 42px;
                    background: url("../assets/images/red-btn.png") center no-repeat;
                    width: 294px;
                    height: 80px;
                    line-height: 80px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    .download-icon {
                        width: 50px;
                        height: 44px;
                        margin-right: 12px;
                    }
                    span {
                        font-size: 36px;
                        font-weight: 400;
                        color: #ffe6e6;
                    }
                }
            }
        }
        .content {
            padding-top: 80px;
            padding-bottom: 80px;
            background: #f1f1f1;
            h1 {
                font-size: 40px;
                font-weight: bold;
                color: #4b4040;
                text-align: center;
                letter-spacing: 2px;
            }
            .red-line {
                width: 180px;
                height: 8px;
                border-radius: 8px;
                background: #e63028;
                margin: 20px auto 0;
            }
            ul {
                width: 60%;
                margin: 60px auto 0;
                li {
                    background: #fff;
                    padding: 60px;
                    margin-bottom: 50px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    .hd {
                        display: flex;
                        align-items: center;
                        padding-bottom: 30px;
                        border-bottom: 1px solid #7d7d7d;
                        .logo {
                            width: 110px;
                            height: 110px;
                            background: #ffffff;
                            box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.2);
                            border-radius: 10px;
                            padding: 10px;
                            box-sizing: border-box;
                            img {
                                width: 90px;
                                vertical-align: middle;
                            }
                        }
                        .version {
                            width: calc(100% - 190px);
                            box-sizing: border-box;
                            padding: 0 40px;
                            h2 {
                                color: #4b4040;
                                font-size: 36px;
                                font-weight: normal;
                                margin-bottom: 16px;
                            }
                            h3 {
                                color: #7d7d7d;
                                font-weight: normal;
                                font-size: 18px;
                            }
                        }
                        .btn {
                            width: 80px;
                            height: 34px;
                            background: #d71913;
                            border-radius: 6px;
                            color: #fff;
                            text-align: center;
                            line-height: 34px;
                            letter-spacing: 2px;
                            cursor: pointer;
                        }
                    }
                    .bd {
                        padding-top: 30px;
                        line-height: 30px;
                    }
                }
            }
        }
    }
</style>