<template>
    <div id="intelligentSystem">
        <div class="banner">
            <img
                class="banner-img"
                src="../assets/images/intelligentSystemBanner.png"
            />
            <div class="download-btn" @click="downloadWindows">立即下载</div>
            <div class="modular">
                <div class="btn-list">
                    <div
                        class="item"
                        v-for="item in btnList"
                        @mouseenter="clickModulat(item.name)"
                    >
                        <img
                            class="item-img"
                            :src="
                                require('../assets/images/' +
                                    item.name +
                                    (click == item.name ? '选中' : '未选中') +
                                    '.png')
                            "
                        />
                        <img
                            class="click-bg"
                            v-if="click == item.name"
                            :id="item.name"
                            src="../assets/images/选中底色.png"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="content download_address">
            <img src="../assets/images/download_address_bg.png" />
            <div class="hd">软件下载<span class="red">版本</span></div>
            <div class="bd">
                <div class="window">
                    <div class="header">
                        <img
                            class="icon"
                            src="../assets/images/windows_icon.png"
                        />
                        <span>下载Windows版本</span>
                    </div>
                    <div class="content">
                        <div><b>版本：</b><br /></div>
                        <div>智能训练系统V{{ latestVersion }}<br /></div>
                        <div><b>支持系统</b><br /></div>
                        <div>支持64位windows7 及以上版本</div>
                    </div>
                    <div class="footer">
                        <img
                            src="../assets/images/立即下载.png"
                            @click="downloadWindows"
                        />
                    </div>
                </div>
                <div class="mac">
                    <div class="header">
                        <img class="icon" src="../assets/images/mac_icon.png" />
                        <span>下载Mac版本</span>
                    </div>
                    <div class="content">
                        <div><b>版本：</b><br /></div>
                        <div>智能训练系统V{{ latestVersion }}<br /></div>
                        <div><b>支持系统</b><br /></div>
                        <div>支持macOS 10.11及以上版本</div>
                    </div>
                    <div class="footer">
                        <img
                            src="../assets/images/立即下载.png"
                            @click="downloadMac"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="content HD_video">
            <img src="../assets/images/HD_video_bg.png" />
            <div class="hd">
                动态<span class="red">无失真</span>的高清视频
                <span class="red">逐帧训练</span>
            </div>
            <div class="bd">
                <img class="img1" src="../assets/images/HD_video_img1.png" />
                <div class="case">
                    <div class="main_title">课堂案例</div>
                    <div class="sub_title">CLASSROOM CASE</div>
                    <i class="iconfont icon-anli"></i>
                    <div class="item"><span>01</span> 标有买卖点</div>
                    <div class="item">
                        <span>02</span> 具有可播放，暂停，快进
                    </div>
                    <div class="item"><span>03</span> 数据精准定点的功能</div>
                </div>
                <div class="train">
                    <div class="main_title">课堂训练</div>
                    <div class="sub_title">ACADEMIC TRAINING</div>
                    <i class="iconfont icon-moxingxunlian"></i>
                    <div class="item"><span>01</span> 可暂停训练</div>
                    <div class="item"><span>02</span> 连续训练</div>
                </div>
                <div class="tips">
                    逐步适应快速变化的<span class="red">盘口数据</span><br />
                    训练学员们的思维和眼睛对<span class="red">买卖点</span
                    >的判断
                </div>
            </div>
        </div>
        <div class="content quotation_replay">
            <img src="../assets/images/quotation_replay_bg.png" />
            <div class="hd">
                十年以上tick<span class="red">数据回放</span>
                任意时间|任意股票的训练和复盘
            </div>
            <div class="bd">
                <div>
                    <img
                        class="img1"
                        src="../assets/images/quotation_replay_img1.png"
                    />
                    <div class="text_block1">
                        <h2>10年</h2>
                        过往10年以上tick数据回放，<br />
                        行情穿越牛熊
                    </div>
                    <div class="text_block2">
                        <h2>任意时间/股票</h2>
                        任意时间/股票的<br />
                        训练和复盘
                    </div>
                </div>
            </div>
        </div>
        <div class="content train_scene">
            <img src="../assets/images/train_scene_bg.png" />
            <div class="hd">手法进阶式<span class="red">训练</span></div>
            <div class="bd">
                <img class="img1" src="../assets/images/train_scene_img1.png" />
                <div class="text_block">
                    <span>从简单到复杂</span><span>从单一到综合</span>
                </div>
            </div>
        </div>
        <div class="content mode_switch">
            <img src="../assets/images/mode_switch_bg.png" />
            <div class="hd"><span class="red">模式切换</span> 智能又人性化</div>
            <div class="bd">
                <img class="img1" src="../assets/images/mode_switch_img1.png" />
                <div class="red_block block">
                    <div class="title">学习模式</div>
                    <div class="describe">专为所有学员学习和训练使用</div>
                </div>
                <div class="white_block block">
                    <div class="title">训练模式</div>
                    <div class="describe">
                        专为交易员复盘、查找做T 操作缺陷，优化做T 策略，提高做T
                        收益率 以及自创新手法使用。
                    </div>
                </div>
            </div>
        </div>
        <div class="content data_analysis">
            <img src="../assets/images/data_analysis_bg.png" />
            <div class="hd">
                「分析」<span class="red">获得数据记录和分析的权限</span>
            </div>
            <div class="bd">
                <img
                    class="img1"
                    src="../assets/images/data_analysis_img1.png"
                />

                <div style="margin-top: -10px; line-height: 26px">
                    所有操作的数据都记录在<span class="red">智能训练系统</span
                    >中，帮助你：
                </div>
                <ul>
                    <li><span class="red">查找</span>做T 操作缺陷</li>
                    <li><span class="red">优化</span>做T 策略</li>
                    <li><span class="red">提高</span>做T 收益率</li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'intelligentSystem',
        data() {
            return {
                latestVersion: '',
                btnList: [
                    {id: '视频课程', image: '视频课程', name: '视频课程'},
                    {id: '实操测试', image: '实操测试', name: '实操测试'},
                    {id: '理论测试', image: '理论测试', name: '理论测试'},
                    {id: '实操训练', image: '实操训练', name: '实操训练'},
                ],
                click: '',
            }
        },
        created() {
            this.getLatestVersion();
        },
        methods: {
            clickModulat(name) {
                this.click = name
            },
            getLatestVersion() {
                let that = this;
                that.$comjs.post('/main/getSysVersionInfo', {}, function (im) {
                    console.log(im)
                    if (im.success) {
                        if (im.data.length > 0) {
                            that.latestVersion = im.data[0].ver;
                        }
                    }
                })
            },
            downloadWindows() {
                window.open(`http://edu.18518.com:80/download/智能训练系统 Setup ${this.latestVersion}.exe`);
            },
            downloadMac() {
                window.open(`http://edu.18518.com:80/download/智能训练系统-${this.latestVersion}.dmg`);
            }
        }
    }
</script>
<style lang="scss">
    #intelligentSystem {
        height: 100%;
        overflow: hidden;
        .banner {
            width: 100%;
            position: relative;
            margin-bottom: 80px;
            .banner-img {
                width: 100%;
            }
            .download-btn {
                width: 324px;
                height: 95px;
                background: #dc3e39;
                border-radius: 20px;
                position: absolute;
                left: 50%;
                bottom: 30%;
                margin-left: -162px;
                font-size: 51px;
                font-weight: 500;
                color: #ffffff;
                text-align: center;
                line-height: 95px;
                letter-spacing: 6px;
                cursor: pointer;
            }
            .banner-btn {
                .btn-list {
                    display: flex;
                    width: 100%;
                    position: relative;
                    bottom: 30px;
                    height: 100%;
                    img {
                        display: block;
                        height: 95px;
                        margin: 0 auto;
                    }
                }
            }

            .modular {
                width: 100%;
                display: flex;
                position: absolute;
                height: 229px;
                background-color: #fff;
                bottom: -80px;
                justify-content: space-around;
                align-items: center;
                .btn-list {
                    display: flex;
                    width: 100%;
                    height: 100%;
                    .item-img {
                        display: block;
                        height: 95px;
                        margin: 0 auto;
                        z-index: 9;
                    }
                    .click-bg {
                        width: 100%;
                        display: block;
                        position: absolute;
                        z-index: 1;
                        left: 0;
                        margin-bottom: 7%;
                    }
                }
                .item {
                    width: 25%;
                    margin: 2% 0;
                    display: flex;
                    position: relative;
                    align-items: center;
                    border-right: 1px solid #e4eaf0;
                }
            }
        }
        .content {
            width: 100%;
            display: block;
            position: relative;
            .red {
                color: #dc3e39;
            }
            > img {
                width: 100%;
                display: block;
            }
            .hd {
                font-size: 26px;
                font-weight: bold;
                color: #2f324d;
                letter-spacing: 2px;
                position: absolute;
                top: 10.8%;
                left: 0;
                right: 0;
                text-align: center;
            }
            &.download_address {
                .bd {
                    width: 100%;
                    position: absolute;
                    z-index: 99;
                    left: 18%;
                    right: 18%;
                    top: 24%;
                    bottom: 0;
                    display: flex;
                    > div {
                        box-shadow: 4px 6px 19px 2px rgba(195, 195, 195, 0.47);
                        border-radius: 10px;
                        width: 30.6%;
                        height: 77%;
                        box-sizing: border-box;
                        padding: 2% 4%;
                        .header {
                            display: flex;
                            justify-items: center;
                            align-items: center;
                            .icon {
                                width: 14%;
                                height: 14%;
                            }
                            span {
                                font-size: 24px;
                                font-weight: bold;
                                margin-left: 10px;
                            }
                        }
                        .content {
                            margin-top: 8%;
                            color: #78777a;
                            height: 40%;
                            > div {
                                height: 25%;
                            }
                            b {
                                color: #000;
                            }
                        }
                        .footer {
                            text-align: center;
                            margin-top: 6%;
                            border-top: 1px solid #d9dee2;
                            padding-top: 6%;
                            img {
                                width: 38%;
                                cursor: pointer;
                            }
                        }
                        + div {
                            margin-left: 30px;
                        }
                    }
                }
            }
            &.HD_video {
                .hd {
                    top: 7.8%;
                }
                .bd {
                    position: absolute;
                    left: 10%;
                    right: 10%;
                    top: 16%;
                    bottom: 0;
                    .img1 {
                        width: 46%;
                        margin-left: 8%;
                        float: left;
                    }
                    .case,
                    .train {
                        padding: 30px 1.4%;
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        box-sizing: border-box;
                    }
                    .case {
                        width: 26.6%;
                        height: 230px;
                        margin-left: 49.4%;
                        margin-top: 3.2%;
                        background-image: url("../assets/images/HD_video_img2.png");
                        position: relative;
                        .iconfont {
                            color: #fff;
                        }
                    }
                    .train {
                        width: 26.6%;
                        height: 196px;
                        background-image: url("../assets/images/HD_video_img3.png");
                        margin-left: 61%;
                        margin-top: -30px;
                        position: relative;
                        .main_title {
                            color: #2f324d;
                        }
                        .sub_title,
                        .item span {
                            color: #dbdbe1;
                        }
                        .item {
                            color: #5e5e65;
                        }
                        .iconfont {
                            color: #e6e6e8;
                        }
                    }
                    .main_title {
                        color: #fff;
                        font-size: 20px;
                    }
                    .sub_title {
                        color: #dbdbe1;
                        font-size: 12px;
                        margin-bottom: 20px;
                    }
                    .item {
                        color: #fff;
                        font-size: 14px;
                        line-height: 34px;
                        display: flex;
                        span {
                            color: #dbdbe1;
                            font-size: 24px;
                            margin-right: 8px;
                        }
                    }
                    .iconfont {
                        font-size: 50px;
                        position: absolute;
                        top: 28px;
                        right: 8%;
                    }
                    .tips {
                        text-align: center;
                        line-height: 30px;
                        font-size: 20px;
                        margin-top: 30px;
                        margin-left: 62%;
                        width: fit-content;
                    }
                }
            }
            &.quotation_replay {
                .hd {
                    top: 10%;
                }
                .bd {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 18%;
                    bottom: 0;
                    text-align: center;
                    font-size: 18px;
                    color: #5e5e65;
                    width: 1200px;
                    margin: 0 auto;
                    > div {
                        position: relative;
                    }
                    .text_block1 {
                        position: absolute;
                        left: 0;
                        top: 26%;
                    }
                    .text_block2 {
                        position: absolute;
                        right: 40px;
                        top: 51%;
                    }
                    h2 {
                        margin-bottom: 16px;
                        color: #2f324d;
                    }
                }
            }
            &.train_scene {
                .bd {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 16%;
                    bottom: 0;
                    text-align: center;
                    .img1 {
                        width: 68%;
                    }
                    .text_block {
                        width: 39.4%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        margin: 10px auto 0;
                        font-size: 20px;
                    }
                }
            }
            &.mode_switch {
                .hd {
                    top: 9.5%;
                }
                .bd {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 28%;
                    bottom: 0;
                    .img1 {
                        width: 100%;
                    }
                    .block {
                        box-sizing: border-box;
                        padding: 26px 70px;
                        text-align: center;
                        .title {
                            font-size: 36px;
                            font-weight: 400;
                        }
                        .describe {
                            font-size: 14px;
                            font-weight: 400;
                        }
                    }
                    .red_block {
                        width: 356px;
                        height: 171px;
                        background: #dc3e39;
                        box-shadow: 1px 2px 5px 0px rgba(195, 195, 195, 0.75);
                        opacity: 0.9;
                        border-radius: 20px;
                        position: absolute;
                        left: 25%;
                        top: 8%;
                        color: #ffffff;
                        .title {
                            margin-bottom: 30px;
                        }
                    }
                    .white_block {
                        width: 356px;
                        height: 171px;
                        background: #ffffff;
                        box-shadow: 1px 2px 5px 0px rgba(195, 195, 195, 0.75);
                        border-radius: 20px;
                        color: #2f324d;
                        position: absolute;
                        right: 23.6%;
                        top: 8%;
                        .title {
                            margin-bottom: 20px;
                        }
                    }
                }
            }
            &.data_analysis {
                .hd {
                    top: 4.6%;
                }
                .bd {
                    position: absolute;
                    left: 21%;
                    right: 21%;
                    top: 6%;
                    bottom: 0;
                    text-align: center;
                    font-size: 20px;
                    .img1 {
                        width: 100%;
                    }
                    ul {
                        display: flex;
                        width: 90%;
                        margin: 40px auto 0;
                        li {
                            width: 300px;
                            height: 60px;
                            line-height: 60px;
                            background: #ffffff;
                            box-shadow: 3px 4px 5px 0px rgba(195, 195, 195, 0.75);
                            margin-right: 10%;
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
</style>