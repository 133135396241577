import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import '@/assets/styles/public.scss'
import '@/assets/iconfont/iconfont.css'
import router from './router'
import store from './store'
import comjs from './js/Common'

Vue.config.productionTip = false
Vue.prototype.$comjs = comjs;
Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
