<template>
    <div id="courseIntroduction">
        <img class="banner" src="../assets/images/kecheng_img_banner.png" />
        <div class="content course_introduct">
            <div class="left_block">
                <div class="main_title">
                    扫码了解更优惠
                </div>
                <img
                    class="img1"
                    src="../assets/images/course_introduct_img1.png"
                />
                <div class="describe">
                    共16节课程<br />支持微信版（仅视频学习）和电脑版【智能训练系统】
                </div>
                <img class="comma" src="../assets/images/comma.png" />
                <div class="qrcode_wrap">
                    <img class="img2" src="../assets/images/vchartcode2.png" />
                </div>
                <img
                    class="img3"
                    src="../assets/images/course_introduct_img3.png"
                />
            </div>
            <div class="right_block">
                <ul>
                    <li>
                        <div class="no">01</div>
                        <div>
                            <span class="right_hd">理论学习</span><br />
                            <span class="right_bd"
                                >文字版（仅智能训练系统查看）&视频版</span
                            >
                        </div>
                    </li>
                    <li>
                        <div class="no">02</div>
                        <div>
                            <span class="right_hd">训练</span><br />
                            <span class="right_bd"
                                >案例训练、课堂训练、综合能力速成训练等</span
                            >
                        </div>
                    </li>
                    <li>
                        <div class="no">03</div>
                        <div>
                            <span class="right_hd">考试</span><br />
                            <span class="right_bd">理论考试&操作考试</span>
                        </div>
                    </li>
                    <li>
                        <div class="no">04</div>
                        <div>
                            <span class="right_hd">内容</span><br />
                            <span class="right_bd" style="line-height: 15px"
                                >全面介绍做T的操作手法、做T理论、风控系统，<br />
                                以及解决做T心理问题，进行心态修炼</span
                            >
                        </div>
                    </li>
                    <li>
                        <div class="no">05</div>
                        <div>
                            <span class="right_hd red"
                                >赠
                                <img
                                    class="img4"
                                    src="../assets/images/course_introduct_img4.png" /></span
                            ><br />
                            <span class="right_bd"
                                >智能训练系统会员6个月</span
                            >
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="content study_guide">
            <img src="../assets/images/study_guide_bg.png" />
            <img
                class="english"
                src="../assets/images/study_guide_english.png"
            />
            <img class="comma" src="../assets/images/comma.png" />
            <div class="hd">
                <span class="red">《几乎无风险T》</span>学习指南
                <div class="describe">
                    有迹交易学院打造一套全新又全面的做T学习体系，让操盘手成长<span
                        class="red"
                        >速度提高3倍</span
                    >
                    <img
                        class="img1"
                        src="../assets/images/study_guide_img1.png"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'courseIntroduction',
        data() {
            return {
            }
        },
    }
</script>
<style lang="scss">
    #courseIntroduction {
        height: 100%;
        .banner {
            display: block;
            width: 100%;
        }
        .content {
            width: 100%;
            display: block;
            position: relative;
            box-sizing: border-box;
            .red {
                color: #dc3e39;
            }
            > img {
                width: 100%;
                display: block;
            }
            .hd {
                font-size: 26px;
                font-weight: bold;
                color: #2f324d;
                letter-spacing: 2px;
                position: absolute;
                top: 10%;
                text-align: center;
                width: 100%;
            }
            &.course_introduct {
                height: 680px;
                background: #fff;
                display: flex;
                padding: 70px 20%;
                .left_block {
                    width: 50%;
                    .img1 {
                        margin-top: 15px;
                        margin-bottom: 20px;
                    }
                    .main_title {
                        font-size: 20px;
                        font-weight: bold;
                        color: #363a55;
                    }
                    .describe {
                        font-size: 14px;
                        font-weight: 400;
                        color: #696970;
                        margin-bottom: 40px;
                    }
                    .qrcode_wrap {
                        width: 98%;
                        height: 310px;
                        background: #ffffff;
                        box-shadow: 6px 6px 35px 8px rgba(176, 187, 204, 0.1);
                        text-align: center;
                        margin-top: -20px;
                        .img2 {
                            width: 46%;
                            margin-top: 26px;
                            border: 1px solid #dc3e39;
                        }
                    }
                    .img3 {
                        margin-left: 58%;
                        margin-top: -66px;
                    }
                }
                .right_block {
                    width: 50%;
                    padding-left: 120px;
                    ul {
                        li {
                            display: flex;
                            align-items: center;
                            height: 66px;
                            margin-bottom: 50px;
                            > div {
                                height: 66px;
                            }
                            .no {
                                font-size: 38px;
                                font-weight: bold;
                                font-style: italic;
                                color: #f2f2f4;
                                margin-right: 16px;
                                line-height: 66px;
                            }
                            .right_hd {
                                font-size: 16px;
                                font-weight: bold;
                                color: #363a55;
                                line-height: 33px;
                                &.red {
                                    color: #dc3e39;
                                }
                                img {
                                    vertical-align: text-bottom;
                                }
                            }
                            .right_bd {
                                font-size: 14px;
                                font-weight: 400;
                                color: #696970;
                                line-height: 30px;
                            }
                            .img3 {
                                vertical-align: text-bottom;
                            }
                        }
                    }
                }
            }
            &.study_guide {
                .english {
                    position: absolute;
                    top: 8%;
                    width: 500px;
                    margin: 0 auto;
                    left: 0;
                    right: 0;
                }
                .comma {
                    position: absolute;
                    width: 40px;
                    top: 3.4%;
                    left: 40%;
                    right: 0;
                }
                .hd {
                    top: 7.4%;
                    .describe {
                        margin-top: 10px;
                        font-size: 16px;
                        font-weight: 400;
                        color: #858d98;
                    }
                    .img1 {
                        width: 60%;
                        display: block;
                        margin: 50px auto 0;
                    }
                }
            }
        }
    }
</style>