import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home.vue'
import layout from '../views/layout.vue'
import download from '../views/download.vue'
import courseIntroduction from '../views/courseIntroduction.vue'
import intelligentSystem from '../views/intelligentSystem.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: layout,
        menuShow: true,
        children: [
            {path: '/', component: home, title: '首页'},
            {path: '/download', component: download, title: '软件下载'},
            {path: '/courseIntroduction', component: courseIntroduction, title: '课程介绍'},
            {path: '/intelligentSystem', component: intelligentSystem, title: '智能系统'},
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
