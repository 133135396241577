import {Message} from 'element-ui'
import axios from 'axios'
import qs from 'qs'

const handleError = (err, isShowErrMsg) => {
    console.log(err);
    if (isShowErrMsg) {
        if (err.message == 'Network Error') {
            Notify({
                type: 'danger',
                message: '网络连接异常，请稍候再试！'
            });
        } else {
            if (err.response && err.response.data) {
                Notify({
                    type: 'danger',
                    message: err.response.data.msg
                });
            }
        }
    }
};

// 存取Authorization
if (typeof (top.Authorization) == 'undefined') {
    top.Authorization = function (authorization) {
        if (authorization === undefined) {
            return localStorage.getItem('Authorization');
        }
        localStorage.setItem('Authorization', authorization);
    }
}

// 存取ServerHostPort
if (typeof (top.ServerHostPort) == 'undefined') {
    top.ServerHostPort = function (serverHostPort) {
        if (serverHostPort === undefined) {
            var shp = localStorage.getItem('ServerHostPort');
            return shp ? shp : window.location.host;
        }
        localStorage.setItem('ServerHostPort', serverHostPort);
    }
}

let host;
if (process.env.NODE_ENV == 'development') {
    host = `192.168.20.28:8090`;
    top.ServerHostPort(host);
} else {
    host = `ste.18518.com:80`;
    top.ServerHostPort(host);
}

if (host && host.indexOf('http://') < 0) {
    host = 'http://' + host;
}

export default {
    get(url, params = {}, callback, exceptCallback, isShowErrMsg = true) {
        let config = this.getRequestConfig(url, callback);
        url = host + config.url;
        config['params'] = params;
        // config['paramsSerializer'] = params => {
        //     return qs.stringify(params, {arrayFormat: 'repeat'})
        // };
        let ret = axios.get(url, config);
        ret.then((res) => {
            config.success(res.data);
        });
        ret.catch(err => {
            handleError(err, isShowErrMsg);
            exceptCallback && exceptCallback(err);
        });
        return ret;
    },

    //exceptCallback: 异常处理的回调函数
    //isShowErrMsg: 是否显示异常信息，在定时请求中不显示
    post(url, params = {}, callback, exceptCallback, isShowErrMsg = true) {
        let config = this.getRequestConfig(url, callback);
        url = host + config.url;
        params = qs.stringify(params);
        let ret = axios.post(url, params, config);
        ret.then((res) => {
            config.success(res.data);
        });
        ret.catch(err => {
            handleError(err, isShowErrMsg);
            exceptCallback && exceptCallback(err);
        });
        return ret;
    },

    getRequestConfig(url, callback) {
        let config = {};
        var ctx = '/ste';
        if (ctx && url.indexOf(ctx) < 0) {
            if (url.indexOf('/') == 0) {
                url = ctx + url;
            }
        }
        config['url'] = url;
        if (callback) {
            config['hasCallback'] = true;
        }
        config['success'] = function (data) {
            if (data && (data.errorType == 'TimeoutToLogin' || data.data == 'TimeoutToLogin')) {
                top.Authorization('');
            }
            if (callback) {
                callback(data)
            }
        };
        config['dataType'] = 'json';
        if (!config.headers) {
            config.headers = {};
        }
        if (!config.headers.Authorization) {
            config.headers.Authorization = top.Authorization();
        }
        config.headers['IudgeCross'] = 'IudgeCross';
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded';

        return config
    },

    showInfoMsg(msg) {
        Message({
            type: 'info',
            message: msg,
            duration: 1500
        })
    },

    showSuccessMsg(msg) {
        Message({
            type: 'success',
            message: msg,
            duration: 1500
        })
    },

    showErrorMsg(msg) {
        Message({
            type: 'error',
            message: msg,
            duration: 1500
        })
    },

    showWarnMsg(msg) {
        Message({
            type: 'warning',
            message: msg,
            duration: 1500
        })
    },

    getToday() {
        let today = $moment();
        return today.format('YYYY-MM-DD');
    },

    getYesterday() {
        let yesterday = $moment().subtract(1, 'days');
        return yesterday.format('YYYY-MM-DD');
    },

    getCurMonthFirst() {
        let curMonthFirst = $moment().set('date', 1);
        return curMonthFirst.format('YYYY-MM-DD');
    },

    //对象数组根据field字段排序
    objectArrSortBy(field, order) {
        return (x, y) => {
            let xtype = typeof (x[field]);
            let ytype = typeof (y[field]);
            if (xtype === 'string' || ytype === 'string') {
                if (order === 'ascending') {
                    return (x[field] != null) - (y[field] != null) || x[field].localeCompare(y[field]);
                }
                if (order === 'descending') {
                    return (x[field] != null) - (y[field] != null) || y[field].localeCompare(x[field]);
                }
            } else {
                if (order === 'ascending') {
                    return (x[field] != null) - (y[field] != null) || x[field] - y[field];
                }
                if (order === 'descending') {
                    return (x[field] != null) - (y[field] != null) || y[field] - x[field];
                }
            }
        }
    },

    //更改按钮的禁用状态
    changeDisabled(list) {
        for (let i = 0; i < list.length; i++) {
            list[i].disabled = !list[i].disabled;
        }
    },

    apply(o, c, defaults) {
        if (defaults) {
            this.apply(o, defaults);
        }
        if (o && c) {
            if (typeof c == 'object') {
                for (var p in c) {
                    o[p] = c[p];
                }
            }
        }
        return o;
    },

    //拷贝数组
    copyArray(arr) {
        var newarr = [];
        for (var i = 0; i < arr.length; i++) {
            if (typeof arr[i] == 'object') {
                var obj = {};
                for (var p in arr[i]) {
                    obj[p] = arr[i][p];
                }
                newarr.push(obj);
            } else {
                newarr.push(arr[i]);
            }
        }
        return newarr;
    },

    numFormat(value, numeralFormat, zeroFormat, nullFormat) {
        if (numeralFormat == undefined) {
            numeralFormat = '0,0.00'
        }
        if (zeroFormat == undefined) {
            zeroFormat = '-'
        }
        if (nullFormat == undefined) {
            nullFormat = ''
        }
        if (typeof (value) === 'number') {
            if (value === 0) {
                return zeroFormat;
            }
            let number = numeral(value);
            let numberFormat = number.format(numeralFormat);
            return numberFormat === 'NaN' ? '' : numberFormat
        } else {
            return nullFormat;
        }
    },

    //数字前补0
    prefixInteger(num, length) {
        return (Array(length).join('0') + num).slice(-length);
    },

    isEmpty(param) {
        let type = typeof (param);
        if (type === 'undefined') {
            return true
        } else if (type === 'object') {
            if (param === null) {
                return true
            } else if (typeof (param.length) === 'undefined') {
                if (JSON.stringify(param) === "{}") {
                    return true;//空值，空对象
                }
            } else if (param.length === 0) {
                return true;//空值，空数组
            }
        } else if (type === 'string') {
            let newParam = param.trim();
            if (newParam.length === 0) {
                //空值，例如:带有空格的字符串" "。
                return true;
            }
        }
        return false;
    },

    getId() {
        return Number(Math.random().toString().substr(3, 3) + Date.now()).toString(36);
    },

    //前面补0
    addPrefixZero(num, length) {
        return (Array(length).join('0') + num).slice(-length);
    },

    //限制输入非负数
    limitNoNegative(e) {
        e[0].target.value = e[0].target.value.replace(/[^0-9.]/g, '')
    },
}
