<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
    export default {
        data() {
            return {
            }
        },
        methods: {

        },
    }
</script>

<style lang="scss">
    @import "assets/styles/font.css";
    #app {
        height: 100%;
        width: 100%;
        min-height: 936px;
        min-width: 1200px;
        font-family: "微软雅黑";
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
</style>
