<template>
    <div id="home">
        <div class="banner">
            <!-- <img class="banner-img" src="../assets/images/banner.png" /> -->
            <el-carousel :height="carouselHeight" trigger="click">
                <el-carousel-item
                    v-for="(item, index) in bannerList"
                    :key="index"
                >
                    <img
                        class="banner-img"
                        :src="require('../assets/images/' + item.image)"
                    />
                </el-carousel-item>
            </el-carousel>
            <div class="text-block">
                <img class="leftyinhao" src="../assets/images/leftyinhao.png" />
                <div>
                    <p class="title">
                        <span>扫码了解《做T基础课程》</span>
                    </p>
                    <p class="describe">
                        <span>必备</span>
                        <span class="seat"></span>
                        <span>赋能</span>
                        <span class="seat"></span>
                        <span>轻松入门</span>
                        <img
                            class="rightyinhao"
                            src="../assets/images/rightyinhao.png"
                        />
                    </p>
                </div>
                <img
                    class="vchartcode"
                    src="../assets/images/vchartcode2.png"
                />
            </div>
        </div>
        <div class="content xuexi_tixi">
            <img
                class="english"
                src="../assets/images/xuexi_tixi_english.png"
            />
            <div class="hd">
                有迹独创<span class="red">股票T0交易</span>学习体系
            </div>
            <div class="bd">
                <ul class="icon-list">
                    <li>
                        <img src="../assets/images/理论及训练.png" /><br />
                        理论及训练<br />
                        <div class="red_line"></div>
                    </li>
                    <li>
                        <img src="../assets/images/考试验证.png" /><br />
                        考试验证<br />
                        <div class="red_line"></div>
                    </li>
                    <li>
                        <img
                            src="../assets/images/综合操盘能力速成.png"
                        /><br />
                        综合操盘能力速成<br />
                        <div class="red_line"></div>
                    </li>
                    <li>
                        <img src="../assets/images/能力分析.png" /><br />
                        能力分析<br />
                        <div class="red_line"></div>
                    </li>
                </ul>
                <img class="img1" src="../assets/images/xuexi_tixi_img1.png" />
                <div
                    style="margin-top: 30px; text-align: center; color: #696970"
                >
                    一套用科技加持的学习体系，用培养机构日内做T交易员的方式培养你
                </div>
            </div>
        </div>
        <div class="content texunying">
            <img class="bg" src="../assets/images/texunying_bg.png" />
            <div class="hd">课程<span class="red">几乎无风险T</span>特训营</div>
            <div class="bd">
                <div class="white_block">
                    <img class="comma" src="../assets/images/comma.png" />
                    内容层层递进，手法从单一到多种综合运用，从理论到实战<br />
                    9大实战交易手法，每种手法都有量化描述，大量历史案例证明，从做T基础手法，到做T心理分析，到做T风控体系，打造完整做T体系<br />
                    理论体系在过去多年，在累计数百上千人的线下培训中得到印证，多年历史数据和案例库，让手法训练穿越牛熊<br />
                    配套训练系统1V1呈现课程，学习效率倍数提升以及自创手法AI分析，帮助分析做T缺陷，优化做T策略
                    <a class="btn" @click="jumpToCourseIntroduction"
                        >我要学习</a
                    >
                </div>
            </div>
            <div class="ft">
                <ul>
                    <li>
                        <i class="iconfont icon-shouye_cengcengdijin"></i>
                        <br />层层递进
                    </li>
                    <li>
                        <i class="iconfont icon-shouye_lianghuamiaoshu"></i>
                        <br />量化描述
                    </li>
                    <li>
                        <i class="iconfont icon-shouye_lishianlizhengming"></i>
                        <br />历史案例证明
                    </li>
                    <li>
                        <i class="iconfont icon-shouye_wanzhengzuoTtixi"></i>
                        <br />完整做T体系
                    </li>
                    <li>
                        <i class="iconfont icon-shouye_xianxiayanzheng"></i>
                        <br />线下验证
                    </li>
                    <li>
                        <i class="iconfont icon-shouye_chuanyueniuxiong"></i>
                        <br />穿越牛熊
                    </li>
                    <li>
                        <i class="iconfont icon-shouye_xuexixiaoshuai"></i>
                        <br />学习效率
                    </li>
                    <li>
                        <i class="iconfont icon-shouye_AIfenxi"></i>
                        <br />AI分析
                    </li>
                </ul>
            </div>
        </div>
        <div class="content learning_system">
            <img class="bg1" src="../assets/images/learning_system_bg1.png" />
            <img class="bg2" src="../assets/images/learning_system_bg2.png" />
            <div class="hd"><span class="red">科技加持</span>智能训练系统</div>
            <div class="bd">
                <div class="learning_system_name">
                    <div class="main_title">有迹交易学院智能训练系统</div>
                    <div class="sub_title">IUDGE EDUCATION</div>
                    <img
                        class="img2"
                        src="../assets/images/learning_system_img2.png"
                    />
                </div>
                <div class="learning_system_content">
                    <div class="learning_system_block">
                        <img src="../assets/images/home_10年.png" />
                        <div class="main_title">10年</div>
                        <div class="sub_title">
                            过往10年以上tick数据回放，<br />行情穿越牛熊
                        </div>
                    </div>
                    <div class="learning_system_block">
                        <img src="../assets/images/home_多.png" />
                        <div class="main_title">多</div>
                        <div class="sub_title">海量训练题库</div>
                    </div>
                    <div class="learning_system_block">
                        <img
                            style="margin-left: -4px"
                            src="../assets/images/home_7x24.png"
                        />
                        <div class="main_title">7*24</div>
                        <div class="sub_title">
                            训练系统支持7*24小时训练<br />便于非交易时间进行高强度<br />训练
                        </div>
                    </div>
                    <div class="learning_system_block">
                        <img src="../assets/images/home_复盘.png" />
                        <div class="main_title">复盘</div>
                        <div class="sub_title">
                            15:30即可回放当日行情，<br />高效复盘
                        </div>
                    </div>
                    <div class="learning_system_block">
                        <img src="../assets/images/home_优.png" />
                        <div class="main_title">优</div>
                        <div class="sub_title">
                            支持盘中实盘模拟交易，降<br />低训练试错成本
                        </div>
                    </div>
                    <div class="learning_system_block">
                        <img
                            style="margin-left: -4px"
                            src="../assets/images/home_验证.png"
                        />
                        <div class="main_title">验证</div>
                        <div class="sub_title">
                            理论和实操考试，验证学习<br />结果，交易能力分析
                        </div>
                    </div>
                </div>
                <div class="learning_system_footer">
                    <img
                        class="img3"
                        src="../assets/images/learning_system_img3.png"
                    />
                    <a class="btn" @click="download"
                        ><i class="iconfont icon-xiazai"></i>立即下载</a
                    >
                </div>
            </div>
        </div>
        <div class="content about_us">
            <img class="english" src="../assets/images/about_us_english.png" />
            <div class="hd">关于我们</div>
            <div class="bd">
                <div class="about_us_block">
                    <div class="main_title">智慧成果</div>
                    <div class="sub_title">INTELLECTUAL ACHIEVEMENTS</div>
                    <div class="about_us_block_content">
                        汇聚了国内顶级交易员的智慧，倾情打造
                    </div>
                    <i class="iconfont icon-shouye_zhihuichengguo"></i>
                </div>
                <div class="about_us_block">
                    <div class="main_title">精心打造</div>
                    <div class="sub_title">BUILDING UP ELABORATE NETWORKS</div>
                    <div class="about_us_block_content">
                        经过6年精心打磨，结合学员的学习成效，<br />不断更新迭代
                    </div>
                    <i class="iconfont icon-shouye_jingxindazao"></i>
                </div>
                <div class="about_us_block">
                    <div class="main_title">科学严谨</div>
                    <div class="sub_title">SCIENTIFIC AND RIGOROUS</div>
                    <div class="about_us_block_content">
                        课程以数学原理、概率论统计为基础
                    </div>
                    <i class="iconfont icon-shouye_kexueyanjin"></i>
                </div>
                <div class="about_us_block">
                    <div class="main_title">以培养操盘手为目的</div>
                    <div class="sub_title">TO CULTIVATE TRADERS</div>
                    <div class="about_us_block_content">
                        培养量化思维、培养数据敏感度、培养<br />下单准确度和速度
                    </div>
                    <i
                        class="iconfont icon-shouye_yipeiyangcaopanshouweimude"
                    ></i>
                </div>
                <div class="about_us_block">
                    <div class="main_title">结果验证</div>
                    <div class="sub_title">RESULT VERIFICATION</div>
                    <div class="about_us_block_content">
                        已培养超过上百名成熟的职业交易员
                    </div>
                    <i class="iconfont icon-shouye_jieguoyanzheng"></i>
                </div>
                <div class="about_us_block">
                    <div class="main_title">更系统、更全面</div>
                    <div class="sub_title">SYSTEMATIC AND COMPREHENSIVE</div>
                    <div class="about_us_block_content">
                        呈现市场上完备的交易策略和手法
                    </div>
                    <i
                        class="iconfont icon-a-shouye_gengxitonggengquanmian"
                    ></i>
                </div>
                <div class="red_line">
                    <div class="item" v-for="count in 4"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'home',
        data() {
            return {
                carouselHeight: '850px',
                latestVersion: '',
                bannerList: [
                    {image: 'home_banner1.png', name: 'banner1'},
                    {image: 'home_banner2.png', name: 'banner2'},
                ],
            }
        },
        created() {
            this.getLatestVersion();
        },
        mounted() {
        },
        methods: {
            getLatestVersion() {
                let that = this;
                that.$comjs.post('/main/getSysVersionInfo', {}, function (im) {
                    console.log(im)
                    if (im.success) {
                        if (im.data.length > 0) {
                            that.latestVersion = im.data[0].ver;
                        }
                    }
                })
            },
            download() {
                window.open(`http://edu.18518.com:80/download/智能训练系统 Setup ${this.latestVersion}.exe`);
            },
            jumpToCourseIntroduction() {
                document.documentElement.scrollTop = 0;
                this.$router.push({path: '/courseIntroduction'});
            }
        },
    }
</script>

<style lang="scss">
    #home {
        height: 100%;
        .el-carousel__arrow {
            background-color: rgba(0, 0, 0, 0.3);
        }
        .banner {
            height: 850px;
            position: relative;
            .el-carousel--horizontal {
                z-index: 1;
            }
            .banner-img {
                width: 100%;
                height: 100%;
            }
            .text-block {
                box-sizing: border-box;
                width: 64%;
                height: 17%;
                background-color: rgba(255, 255, 255, 0.5);
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: 0 auto;
                padding: 24px 0 0 10%;
                box-shadow: 0px 1px 43px 8px rgba(45, 45, 45, 0.13);
                display: flex;
                z-index: 9;
                .leftyinhao {
                    width: 4%;
                    height: 28%;
                    margin-right: 6%;
                    margin-top: 7px;
                }
                .title {
                    font-size: 38px;
                    color: #363a55;
                    letter-spacing: 2px;
                    margin-bottom: 5px;
                    margin-top: 13px;
                    .key-words {
                        color: #e54545;
                        font-weight: bold;
                    }
                }
                .describe {
                    color: #363a55;
                    font-size: 20px;
                    display: flex;
                    align-items: center;
                    .seat {
                        margin: 0 3%;
                        display: inline-block;
                        width: 1px;
                        height: 13px;
                        border-left: 1px solid #363a55;
                    }
                    .rightyinhao {
                        margin-left: 17%;
                        padding-top: 6px;
                    }
                }
                .vchartcode {
                    width: 100px;
                    height: 100px;
                    margin-left: 3%;
                }
            }
        }
        .content {
            width: 100%;
            position: relative;
            .content-img {
                width: 100%;
                display: block;
            }
            #learningSystem {
                margin-top: 43px;
            }
            .red {
                color: #dc3e39;
            }
            > img {
                display: block;
            }
            .hd {
                font-size: 26px;
                font-weight: bold;
                color: #2f324d;
                letter-spacing: 2px;
                position: absolute;
                top: 8%;
                left: 0;
                right: 0;
                text-align: center;
            }
            &.xuexi_tixi {
                height: 1080px;
                background: #fff;
                .english {
                    position: absolute;
                    top: 7%;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }
                .hd {
                    top: 6.6%;
                }
                .bd {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 15%;
                    bottom: 0;
                    text-align: center;
                    .icon-list {
                        display: flex;
                        width: 40%;
                        flex-wrap: nowrap;
                        justify-content: space-between;
                        margin: 0 auto;
                        li {
                            text-align: center;
                            font-size: 14px;
                            color: #363a55;
                            img {
                                width: 54px;
                                margin-bottom: 10px;
                            }
                            .red_line {
                                width: 22px;
                                height: 2px;
                                background: #dc3e39;
                                margin: 10px auto 0;
                            }
                        }
                    }
                    .img1 {
                        margin-top: 50px;
                        width: 80%;
                    }
                }
            }
            &.texunying {
                img {
                    width: 100%;
                }
                .hd {
                    top: 11%;
                }
                .bd {
                    position: absolute;
                    left: 18%;
                    right: 18%;
                    top: 26%;
                    bottom: 20%;
                    @media screen and (max-width: 1500px) {
                        .white_block {
                            padding: 18px 30px;
                        }
                    }
                    .white_block {
                        width: 742px;
                        background: #ffffff;
                        box-shadow: 0px 21px 35px 8px rgba(176, 187, 204, 0.17);
                        margin: 0 auto;
                        color: #858d98;
                        box-sizing: border-box;
                        padding: 54px 30px;
                        font-size: 14px;
                        letter-spacing: 1px;
                        line-height: 24px;
                        position: relative;
                        .comma {
                            width: 40px;
                            position: absolute;
                            top: -10px;
                            left: -23px;
                        }
                        .btn {
                            width: 160px;
                            height: 48px;
                            line-height: 48px;
                            background: #e54545;
                            border-radius: 12px;
                            display: inline-block;
                            font-size: 22px;
                            color: #ffffff;
                            position: absolute;
                            right: -10px;
                            bottom: -23px;
                            text-align: center;
                            cursor: pointer;
                        }
                    }
                }
                .ft {
                    position: absolute;
                    left: 22%;
                    right: 22%;
                    bottom: 8%;
                    ul {
                        display: flex;
                        font-size: 14px;
                        color: #858d98;
                        flex-wrap: nowrap;
                        justify-content: space-between;
                        li {
                            text-align: center;
                            line-height: 30px;
                            i {
                                color: #dc3e39;
                                font-size: 36px;
                                margin-bottom: 6px;
                            }
                        }
                    }
                }
            }
            &.learning_system {
                .bg1,
                .bg2 {
                    width: 100%;
                }
                .hd {
                    top: 7.6%;
                }
                .bd {
                    position: absolute;
                    left: 22%;
                    right: 22%;
                    top: 17%;
                    bottom: 0;
                    .learning_system_name {
                        width: 469px;
                        height: 271px;
                        background-image: url("../assets/images/learning_system_img1.png");
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        color: #fff;
                        box-sizing: border-box;
                        padding: 30px 50px;
                        float: left;
                        .main_title {
                            font-size: 20px;
                        }
                        .sub_title {
                            font-size: 15px;
                            margin-top: 10px;
                            margin-bottom: 30px;
                        }
                    }
                    .learning_system_content {
                        width: 71%;
                        height: 68%;
                        background: #ffffff;
                        box-shadow: 0px 21px 35px 8px rgba(176, 187, 204, 0.15);
                        border-radius: 5px;
                        position: relative;
                        left: 340px;
                        top: -170px;
                        z-index: 99;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        .learning_system_block {
                            width: 33.33%;
                            height: 50%;
                            border-right: 1px solid #f2f2f4;
                            box-sizing: border-box;
                            padding-left: 5%;
                            padding-top: 6%;
                            &:nth-child(3n) {
                                border-right: 0;
                            }
                            &:nth-child(1),
                            &:nth-child(2),
                            &:nth-child(3) {
                                border-bottom: 1px solid #f2f2f4;
                            }
                            > img {
                                width: 50px;
                            }
                            .main_title {
                                font-size: 18px;
                                color: #363a55;
                                margin-top: 6%;
                                margin-bottom: 6%;
                                font-weight: bold;
                            }
                            .sub_title {
                                font-size: 14px;
                                color: #696970;
                            }
                        }
                    }
                    .learning_system_footer {
                        position: absolute;
                        bottom: 7%;
                        left: 0;
                        right: 0;
                        text-align: center;
                        vertical-align: middle;
                        img {
                            vertical-align: middle;
                        }
                        .btn {
                            width: 160px;
                            height: 48px;
                            line-height: 48px;
                            background: #ffffff;
                            border-radius: 10px;
                            display: inline-block;
                            vertical-align: middle;
                            font-size: 22px;
                            color: #e14444;
                            margin-left: 26px;
                            cursor: pointer;
                            i {
                                font-size: 24px;
                                vertical-align: bottom;
                                margin-right: 4px;
                            }
                        }
                    }
                }
            }
            &.about_us {
                height: 580px;
                background: #fff;
                .english {
                    position: absolute;
                    top: 9%;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }
                .bd {
                    position: absolute;
                    left: 18%;
                    right: 18%;
                    top: 24%;
                    bottom: 0;
                    .red_line {
                        display: flex;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 190px;
                        bottom: 0;
                        .item {
                            height: 1px;
                            width: 100px;
                            background: #dc3e39;
                            &:nth-child(1),
                            &:nth-child(3) {
                                margin-right: calc(33.33% - 150px);
                            }
                            &:nth-child(2) {
                                margin-right: calc(33.33% - 100px);
                            }
                        }
                    }
                    .about_us_block {
                        width: 33.33%;
                        height: 190px;
                        border: 1px solid #f2f2f4;
                        box-sizing: border-box;
                        padding: 26px 20px;
                        position: relative;
                        float: left;
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3) {
                            border-bottom: 0;
                        }
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(4),
                        &:nth-child(5) {
                            border-right: 0;
                        }
                        .main_title {
                            font-size: 18px;
                            color: #363a55;
                            font-weight: bold;
                        }
                        .sub_title {
                            font-size: 12px;
                            color: #e0e0e4;
                            margin-top: 2px;
                        }
                        .about_us_block_content {
                            font-size: 14px;
                            color: #696970;
                            margin-top: 36px;
                        }
                        .iconfont {
                            position: absolute;
                            top: 26px;
                            right: 20px;
                            font-size: 50px;
                            color: #e9e9eb;
                        }
                    }
                }
            }
        }
    }
</style>