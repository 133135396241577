<template>
    <div style="height: 100%">
        <!-- <div id="header" :class="headerActive ? 'active' : ''">
            <img class="logo" src="../assets/images/logo.png" />
            <el-tabs class="menu-list" v-model="activeName" @tab-click="handleClick">
                <el-tab-pane v-for="(item) in menuList" :label="item.title" :name="item.path"></el-tab-pane>
            </el-tabs>
        </div> -->
        <div id="header" :class="headerActive ? 'active' : ''">
            <img class="logo" src="../assets/images/logo.png" />
            <ul class="menu-list">
                <li
                    v-for="item in menuList"
                    :class="item.path == $route.path ? 'active' : ''"
                    @click="clickMenuItem(item)"
                >
                    {{ item.title }}
                </li>
            </ul>
        </div>
        <div id="main" :class="headerActive ? 'active' : ''">
            <router-view />
        </div>
        <!-- <div id="footer" :class="$route.path == '/download' ? 'black' : ''">
            Copyright @2016 厦门有迹科技有限公司 版权所有。
            <a href="https://beian.miit.gov.cn/" target="_blank">闽ICP备17004963号-5</a>
        </div>-->
        <div id="footer">
            <img class="map" src="../assets/images/Map.png" />
            <div class="footer_wrap">
                <div class="left_block">
                    <div class="introduce">
                        <img src="../assets/images/logo2.png" />
                        <div>
                            有迹交易学院<br />
                            为市场提供了一套经线下培训验证的、理论+实操的训练体系，已培养成熟交易员上千名；同时结合有迹配套的训练系统，可提升学习效能，缩短学习时间
                        </div>
                    </div>
                    <div class="address">
                        <div>
                            联系地址：福建省厦门市软件园三期B区10栋11楼 |
                            联系邮箱：edu.18518@iudge.cn |
                            联系电话：0592-5193590
                        </div>
                        <div>
                            <a href="https://beian.miit.gov.cn/" target="_blank"
                                >闽ICP备17004963号-5</a
                            >
                            | <img src="../assets/images/jinghui.png" />
                            <span
                                >企业营业执照注册号：91350200MA344TRF12 |
                                Copyright © 厦门有迹科技有限公司 版权所有</span
                            >
                        </div>
                    </div>
                </div>
                <div class="right_block">
                    <span>有迹交易学习平台</span><br /><img
                        src="../assets/images/vchartcode2.png"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                headerActive: false,
                menuList: [
                    {title: '首页', path: '/'},
                    {title: '课程介绍', path: '/courseIntroduction'},
                    {title: '软件下载', path: '/intelligentSystem'},
                    {title: '关于我们', path: '/aboutUs'},
                ],
            }
        },
        mounted() {
            let that = this;
            window.addEventListener('scroll', function () {
                let t = document.documentElement.scrollTop;   // 目前监听的是整个body的滚动条距离
                if (t > 0) {
                    that.headerActive = true;
                } else {
                    that.headerActive = false;
                }
            })
        },
        methods: {
            clickMenuItem(item) {
                if (item.path == '/personalCenter' || item.path == '/login') {
                    this.$comjs.showWarnMsg('开发中，敬请期待！');
                    return
                }
                document.documentElement.scrollTop = 0;
                if (item.path == '/aboutUs') {
                    this.$router.push({path: '/'});
                    document.querySelector('#footer').scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
                    return
                }
                this.$router.push({path: item.path});
            },
            handleClick(tab, event) {
                console.log(tab, event, tab.name);
                if (tab.label == '关于我们') {
                    document.querySelector('#footer').scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
                    return
                }
                document.querySelector('#main').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
                this.$router.push({path: '/' + tab.name});
            }
        }
    }
</script>

<style lang="scss">
    #header {
        height: 100px;
        background: #fff;
        padding: 20px 18%;
        box-sizing: border-box;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        position: relative;
        left: 0;
        right: 0;
        top: 0;
        z-index: 999;
        &.active {
            position: fixed;
        }
        .logo {
            user-select: none;
            width: 350px;
        }
        // .menu-list {
        //     .el-tabs__nav {
        //         height: 52px;
        //     }
        //     .el-tabs__nav-wrap::after {
        //         background-color: transparent;
        //     }
        //     .el-tabs__item {
        //         padding: 0 50px;
        //     }
        //     .el-tabs__item:hover {
        //         color: #3d3131;
        //     }
        //     .el-tabs__item.is-active {
        //         color: #e54545;
        //     }
        //     .el-tabs__active-bar {
        //         background-color: #e54545;
        //     }
        // }
        .menu-list {
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 400;
            font-size: 22px;
            user-select: none;
            li {
                width: 154px;
                text-align: center;
                height: 48px;
                line-height: 48px;
                margin-right: 30px;
                cursor: pointer;
                border-radius: 8px;
                color: #868686;
                &:last-child {
                    margin-right: 0;
                }
                &.active,
                &:hover {
                    background: #e63028;
                    color: #fff;
                }
            }
        }
    }
    #main {
        min-height: calc(100% - 226px);
        &.active {
            margin-top: 106px;
        }
    }
    #footer {
        text-align: center;
        color: #3d3131;
        font-size: 16px;
        user-select: none;
        position: relative;
        background: #21212a;
        .leftyinhao {
            display: block;
            width: 100%;
        }
        &.black {
            background: #161615;
            color: #fff;
            a {
                color: #fff;
                &:hover {
                    color: #e63028;
                }
            }
        }
        .map {
            width: 100%;
            display: block;
        }
        .footer_wrap {
            height: 194px;
            box-sizing: border-box;
            padding-top: 26px;
            padding-bottom: 20px;
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 1084px;
            .left_block {
                width: 85%;
                height: 85px;
                border: 1px solid #35353f;
                .introduce {
                    display: flex;
                    font-size: 12px;
                    font-weight: 300;
                    color: #5c5c73;
                    line-height: 30px;
                    text-align: left;
                    align-items: center;
                    height: 100%;
                    img {
                        width: 52px;
                        height: 52px;
                        margin: 0 18px;
                    }
                }
                .address {
                    color: #5c5b73;
                    font-size: 14px;
                    padding-left: 18px;
                    text-align: left;
                    line-height: 30px;
                    margin-top: 8px;
                    a {
                        color: #5c5b73;
                        text-decoration: none;
                        &:hover {
                            color: #e63028;
                        }
                    }
                    img {
                        margin: 0 2px;
                    }
                    img,
                    span {
                        vertical-align: middle;
                    }
                }
            }
            .right_block {
                font-size: 14px;
                font-weight: 400;
                color: #5c5b73;
                img {
                    margin-top: 16px;
                    width: 116px;
                }
            }
        }
    }
</style>
